@import "~simplebar/dist/simplebar.css";
@import "~highlight.js/styles/androidstudio.css";
// Vex Core
@import "./@vex/styles/core";

/*
  You can override any CSS Variable or style here
  Example:

  :root {
   --sidenav-background: #333333;
  }

  All possible variables can be found in @vex/styles/core or by simply inspecting the element you want to change
  in Chrome/Firefox DevTools
*/

/**
  Uncomment the below code and adjust the values to fit your colors, the application will automatically adjust
 */
:root {
  // --color-primary: blue;
  // --color-primary-contrast: white;

  // --color-accent: yellow;
  // --color-accent-contrast: black;

  // --color-warn: yellow;
  // --color-warn-contrast: black;
}

.cull-full-width {
  width: 100%;
}

.cull-text-icon {
    vertical-align: middle;
}


.cull-dropdown {
  background: var(--background-foreground);
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
  box-shadow: var(--elevation-z4);
  max-width: 100vw;
  overflow: hidden;
  width: 350px;
  @apply rounded;
}

.cull-dropdown-header {
  @apply bg-primary text-primary-contrast py-4 pr-4 pl-3 shadow;
}

.cull-dropdown-header-warning {
  @apply bg-warn text-primary-contrast py-4 pr-4 pl-3 shadow;
}

.cull-dropdown-heading-icon {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 999999px;
  margin-right: var(--padding-12);
  padding: var(--padding-8);

  .mat-icon {
    font-size: 32px;
    height: 32px;
    width: 32px;
  }
}

.cull-dropdown-heading {
  font: var(--font-title);
}

.cull-dropdown-content {
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
}

.cull-dropdown-footer {
  background: var(--background-app-bar);
  border-top: 1px solid var(--foreground-divider);
  padding: var(--padding-8) var(--padding-12);
}

.cull-dropdown-footer-select {
  padding-left: var(--padding-12);

  .mat-icon:not(.dropdown-footer-select-caret) {
    margin-right: var(--padding-8);
    vertical-align: -7px !important;
  }
}

.cull-dropdown-footer-select-caret {
  color: var(--text-hint);
  font-size: 18px;
  height: 18px;
  vertical-align: -4px !important;
  width: 18px;
}
